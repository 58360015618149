import React from 'react';
import styled from 'styled-components';
import HomeHero from '../components/home/HomeHero';
import HomeExperiences from '../components/home/HomeExperiences';
import HomeLineup from '../components/home/HomeLineup';
import HomeLineupchefs from '../components/home/HomeLineupChefs';
import Covid from '../components/covid/covid';
// home images

const HomePage = styled.div``;

export default function Home() {
  return (
    <>
      <HomeHero />

      <HomeLineup />
      <HomeLineupchefs />
      <HomeExperiences />
    </>
  );
}

import React from 'react';
import styled from 'styled-components';
// import Swiper core and required components
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import AniLink from 'gatsby-plugin-transition-link/AniLink';

import experiences1 from '../../assets/images/experiences/chefhands_web.jpg';
import experiences2 from '../../assets/images/experiences/myanalogjournal_web.jpg';
import experiences3 from '../../assets/images/experiences/pool.gif';
import experiences4 from '../../assets/images/experiences/wellness.jpg';
import experiences5 from '../../assets/images/experiences/tigersafari.jpg';
import experiences6 from '../../assets/images/experiences/bagichi.jpg';
import experiences10 from '../../assets/images/experiences/peacock.jpg';
import eye from '../../assets/images/icons/eye.svg';
import compass from '../../assets/images/icons/compass.svg';
import food from '../../assets/images/icons/food.svg';
import mouth from '../../assets/images/icons/mouth.svg';
import sound from '../../assets/images/icons/sound.svg';
import leftArrow from '../../assets/images/swiper/swiper_left.svg';
import rightArrow from '../../assets/images/swiper/swiper_right.svg';
import Button from '../button';

// transitionimage
import transitionimage from '../../assets/images/transition.jpg';

// Import Swiper styles
import 'swiper/swiper-bundle.min.css';

// install Swiper components
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay]);

// home experiences
const ExperiencesSliderStyled = styled.div`
  position: relative;
`;

const SlideCard = styled.div`
  padding: 0px 10px;
  position: relative;

  padding-top: 10px;
  border: 1px solid rgba(0, 0, 0, 0);
  transition: all 0.2s ease-in;
  &:hover {
    border: 1px solid var(--grey);
  }
  a {
    text-decoration: none;
  }
  .text {
    font-size: 16px;
    padding: 10px;
    padding-right: 20px;
    position: relative;
    font-family: 'IBM Regular', sans-serif;

    .title {
      text-transform: uppercase;
      font-size: 1.2rem;
      margin-top: 5px;
      text-decoration: underline;
    }
    .copy {
      margin-top: 15px;
      max-width: 80%;
      line-height: 1.4;
    }
    .footer {
      img {
        width: 30px;
      }
    }
    .footer {
      position: absolute;
      right: 0;
      top: 8px;
      img {
        width: 30px;
      }
    }
  }
`;

const SwiperController = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 110%;
  @media (min-width: 768px) {
    transform: translateX(0);
    left: 80%;
    top: -100px;
  }
  transition: all 0.3s ease-in;
  .swiper-right {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
  .swiper-left {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }

  img {
    max-width: 100%;
  }
`;

const LastCard = styled.div`
  border: 0px solid var(--grey);
  height: 100%;
  padding: 15px;
  margin: 0 15px;
  height: 234px;
  img {
    width: 30px;
    display: inline-block;
    margin: 0px 5px 0px 0px;
    margin-bottom: 15px;
  }
  .title {
    font-size: 1rem;
    margin-bottom: 15px;
  }
  .body {
    margin-bottom: 15px;
  }
`;

const transitionImageDiv = `url('${transitionimage}') center / cover  no-repeat fixed padding-box content-box white`;

export default function Slider() {
  const params = {
    freeMode: true,
    speed: 1000,
    navigation: {
      nextEl: '.swiper-next',
      prevEl: '.swiper-prev',
    },

    spaceBetween: 0,
    breakpoints: {
      640: {
        spaceBetween: 0,
        centeredSlides: true,
        slidesPerView: 2,
      },
      768: {
        spaceBetween: 0,
        slidesPerView: 2,
        centeredSlides: true,
      },
      1024: {
        spaceBetween: 0,
        slidesPerView: 3,
        centeredSlides: true,
      },
      1300: {
        spaceBetween: 0,
        slidesPerView: 4,
        centeredSlides: true,
        initialSlide: 1,
      },
    },
  };
  return (
    <ExperiencesSliderStyled>
      <SwiperController>
        <div className="swiper-left swiper-prev">
          <img width="50px" src={leftArrow} alt="" />
        </div>
        <div className="swiper-right swiper-next">
          <img width="50px" src={rightArrow} alt="" />
        </div>
      </SwiperController>
      <Swiper {...params}>
        <SwiperSlide>
          <SlideCard>
            <AniLink
              className="link back b-i"
              to="/experiences/chefs-table"
              cover
              direction="down"
              activeClassName="active"
              bg={transitionImageDiv}
            >
              <div className="SlideCardWrap">
                <img src={experiences1} alt="" />
                <div className="text">
                  <div className="footer">
                    <img src={food} alt="" />
                  </div>
                  <div className="title b">THE CHEF’S TABLE</div>
                  <div className="copy">
                    Come get up-close-and-personal with handpicked chefs from
                    across India at an intimate, seated dining experience.
                  </div>
                </div>
              </div>
            </AniLink>
          </SlideCard>
        </SwiperSlide>
        <SwiperSlide>
          <AniLink
            className="link back b-i"
            to="/experiences/easy-picnic"
            cover
            direction="down"
            activeClassName="active"
            bg={transitionImageDiv}
          >
            <SlideCard>
              <img src={experiences2} alt="" />
              <div className="text">
                <div className="footer">
                  <img src={sound} alt="" />
                </div>
                <div className="title b">THE EASY PICNIC</div>
                <div className="copy">
                  Find a cosy spot, a refreshing cocktail, some people you love
                  and let the warm rays of the day soak into your soul.
                </div>
              </div>
            </SlideCard>
          </AniLink>
        </SwiperSlide>
        <SwiperSlide>
          <AniLink
            className="link back b-i"
            to="/experiences/a-grand-pool-party"
            cover
            direction="down"
            activeClassName="active"
            bg={transitionImageDiv}
          >
            <SlideCard>
              <img src={experiences3} alt="" />
              <div className="text">
                <div className="footer">
                  <img src={sound} alt="" />
                </div>
                <div className="title b">Ray-Ban Studios Pool Party</div>
                <div className="copy">
                  Carved gazebos, inviting sun loungers, handcrafted summer
                  cocktails and joy-inducing tropical sounds is what awaits you
                  at the Ray-Ban Studios Pool Party.
                </div>
              </div>
            </SlideCard>
          </AniLink>
        </SwiperSlide>
        <SwiperSlide>
          <AniLink
            className="link back b-i"
            to="/experiences/magnetic-sanctuary"
            cover
            direction="down"
            activeClassName="active"
            bg={transitionImageDiv}
          >
            <SlideCard>
              <img src={experiences4} alt="" />
              <div className="text">
                <div className="footer">
                  <img src={eye} alt="" />
                </div>
                <div className="title b">The Magnetic Sanctuary</div>
                <div className="copy">
                  Magnetic Sanctuary is a tranquil retreat within the festival
                  where you can revive and revitalise your body and mind through
                  a variety of wellness workshops.
                </div>
              </div>
            </SlideCard>
          </AniLink>
        </SwiperSlide>
        <SwiperSlide>
          <AniLink
            className="link back b-i"
            to="/experiences/sundowner"
            cover
            direction="down"
            activeClassName="active"
            bg={transitionImageDiv}
          >
            <SlideCard>
              <img src={experiences6} alt="" />
              <div className="text">
                <div className="footer">
                  <img src={sound} alt="" />
                </div>
                <div className="title b">Jameson Sundowner</div>
                <div className="copy">
                  Dance around the warm rays of the afternoon sun as they peek
                  through the canopy of leaves. As the sun sets, we will take
                  you on a magical journey into the night with some of India’s
                  best DJs.
                </div>
              </div>
            </SlideCard>
          </AniLink>
        </SwiperSlide>
        <SwiperSlide>
          <SlideCard>
            <AniLink
              className="link back b-i"
              to="/ranthambore"
              cover
              direction="down"
              activeClassName="active"
              bg={transitionImageDiv}
            >
              <img src={experiences5} alt="" />
              <div className="text">
                <div className="footer">
                  <img src={compass} alt="" />
                </div>
                <div className="title b">Jungle Safaris</div>
                <div className="copy">
                  No visit to Ranthambore is complete without a jungle safari.
                </div>
              </div>
            </AniLink>
          </SlideCard>
        </SwiperSlide>
        <SwiperSlide>
          <SlideCard>
            <AniLink
              className="link back b-i"
              to="/experiences/the-peacock-club"
              cover
              direction="down"
              activeClassName="active"
              bg={transitionImageDiv}
            >
              <img src={experiences10} alt="" />
              <div className="text">
                <div className="footer">
                  <img src={compass} alt="" />
                </div>
                <div className="title b">The Peacock Club</div>
                <div className="copy">
                  The majestic traveling theatre arives at Magnetic Fields
                  Nomads, offering a rich program of cabaret inspired
                  performances.
                </div>
              </div>
            </AniLink>
          </SlideCard>
        </SwiperSlide>
        <SwiperSlide>
          <LastCard>
            <img src={eye} alt="" /> <img src={food} alt="" />{' '}
            <img src={sound} alt="" /> <img src={compass} alt="" />
            <div className="title b-i">Discover Magnetic Fields Nomads</div>
            <div className="body">
              From cocktail bars to dining in the grass, find out more of what
              to expect.
            </div>
            <Button linkto="/experiences" linktext="Explore All" />
          </LastCard>
        </SwiperSlide>
      </Swiper>
    </ExperiencesSliderStyled>
  );
}

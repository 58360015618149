import React from 'react';
import styled from 'styled-components';
import tree4 from './motifs/tree_4.png';

const Motif1styles = styled.div`
  position: absolute;
  left: 10%;
  top: 90%;
  z-index: 0;
  width: 8vw;
  max-width: 80px;
  opacity: 0.2;
`;

export default function Motif1() {
  return (
    <>
      <Motif1styles>
        <img src={tree4} alt="" />
      </Motif1styles>
    </>
  );
}

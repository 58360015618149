import React from 'react';
import styled from 'styled-components';
import mountain1 from './motifs/mountain_2.png';

const Motif1styles = styled.div`
  position: absolute;
  left: 15%;
  top: 80%;
  z-index: 0;
  width: 8vw;
  max-width: 150px;
  opacity: 0.2;
`;

export default function Motif1() {
  return (
    <>
      <Motif1styles>
        <img src={mountain1} alt="" />
      </Motif1styles>
    </>
  );
}

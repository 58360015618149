import React from 'react';
import styled from 'styled-components';
import { gsap } from 'gsap';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
// import splitting from 'splitting';
import HomeExperiencesSlider from './HomeExperiencesSlider';
import travelline from '../../assets/images/travel_line_2.svg';
import experiences from '../../assets/images/home/experiences.png';
import bird2 from '../../assets/images/birds/bird2.png';
import Button from '../button';

// motifs
import Trees from '../motifs/motif13';
import Tigers from '../motifs/motif14';
import Mountain from '../motifs/motif12';

const Bird = styled.div`
  position: absolute;
  left: -20px;
  top: 40px;
  z-index: 0;
  width: 40vw;
  max-width: 300px;
  height: auto;
  opacity: 0.6;
  transform: translateY(-50%) rotate(0deg);
`;
// home experiences
const ExperiencesStyled = styled.div`
  background: url(${travelline});
  background-size: 100%;
  background-repeat: no-repeat;
  position: relative;
  a {
    text-decoration: none;
  }
`;
const Spacer = styled.div`
  height: 200px;
  content: '';
`;
const HomeExperiences = styled.div`
  grid-column: 1 / span 24;
  @media (min-width: 768px) {
    grid-column: 1 / span 10;
  }
  @media (min-width: 1120px) {
    grid-column: 1 / span 8;
  }
  font-size: 1em;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  font-size: 1em;
  position: relative;
  overflow: visible;
  .title {
    font-family: 'Magnetic Fields', Arial, Helvetica, sans-serif;
    text-transform: uppercase;
    font-size: 2.2rem;
    @media (min-width: 768px) {
      font-size: 3rem;
    }
    padding: 15px 0 0px 0;
  }
  .text {
    font-size: 1rem;
    line-height: 1.4;
  }
`;

const HomeExperiencesImage = styled.div`
  grid-column: 1 / span 24;
  @media (min-width: 768px) {
    grid-column: 11 / span 24;
  }
  @media (min-width: 1120px) {
    grid-column: 10 / span 24;
  }

  opacity: 0.65;
`;

export default function Experiences() {
  return (
    <ExperiencesStyled>
      <Bird>
        <img src={bird2} alt="" />
      </Bird>
      <Trees />
      <Tigers />
      <Mountain />
      <div className="SiteContainer">
        <HomeExperiences className="">
          <div className="title">
            Discover Nomads Through intimate curated experiences.
          </div>
          <div className="text">
            <p className="b-i">The very best of the fields.</p>
            <p>
              A magical getaway where you will be constantly surprised and
              delighted; where you can wander and wake up to the sounds of
              nature in one of the world’s most unique and surreal venues.
            </p>
            <Button linkto="/experiences" linktext="Discover All" />
          </div>
        </HomeExperiences>
        <HomeExperiencesImage>
          <img src={experiences} alt="" />
        </HomeExperiencesImage>
      </div>
      <HomeExperiencesSlider />
      <Spacer />
    </ExperiencesStyled>
  );
}

import React, { useState, useRef, useLayoutEffect } from 'react';
import styled from 'styled-components';
import { motion, useViewportScroll, useTransform } from 'framer-motion';
import HomeImage from './HomeImage';
import HomeHeroImage from './HomeHeroimage';
import Travelline from '../travellines/Travelline1';
import Travelline3 from '../travellines/Travelline3';

import heroSymbol from '../../assets/images/symbol_rust.svg';
import starRust from '../../assets/images/star_rust.svg';
import Button from '../button';

// motifs

import Mountain1 from '../motifs/motif3';
import Tiger1 from '../motifs/motif4';
import Tree1 from '../motifs/motif5';
import Fort1 from '../motifs/motif6';
import Tree2 from '../motifs/motif7';
import Tree3 from '../motifs/motif8';
import River2 from '../motifs/motif9';
import Tree4 from '../motifs/motif10';
import Mountain2 from '../motifs/motif11';
// home images
const HomeHero = styled.div`
  padding: 7.5vw 0 0 0;
  grid-column: 1 / -1;
  .HeroImagewrapper {
    height: 70vh;
    overflow: hidden;

    // display: flex;
    // align-items: center;

    img {
      max-width: unset;
      width: 120%;
      height: 120%;
      object-fit: cover;
      object-position: center;
      // width: 100%;
      @media (min-width: 768px) {
        max-width: unset;
        width: 120%;
        height: 120%;
        object-fit: cover;
        object-position: center;
      }
    }
  }
`;

const Symbol = styled.div`
  width: 200px;
  height: 200px;
  position: absolute;
  left: -200px;
  top: 250px;
  z-index: 2;
  opacity: 0.5;
`;

const HomeTopwrap = styled.div`
  position: relative;
`;

const ImagesWrap = styled.div`
  width: 100vw;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;
`;

const HomeIntrowrap = styled.div`
  position: relative;
  min-height: 100vw;

  display: block;
  @media (min-width: 768px) {
    display: block;
  }
`;

const HomeIntro = styled.div`
  grid-column: 1 / -1;
  font-size: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  .homeIntroWrapper {
    text-align: center;
    padding: 4.17vw 0;
    max-width: 400px;
    img {
      width: 100%;
    }

    .title {
      font-family: 'Magnetic Fields', Arial, Helvetica, sans-serif;
      text-transform: uppercase;
      font-size: 2.2rem;
      padding: 30px;
    }
    .text {
      font-size: 1rem;
      line-height: 1.4;
    }

    .animateLIne {
      margin-top: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .star {
        width: 30px;
        transform: translateX(-1px);
      }
    }
    .finish {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .star {
        margin-top: 10px;
      }
    }
    .progress-icon {
      margin-top: 5px;
      height: 300px;
      width: 2px;
      display: flex;
      align-items: center;
      justify-content: center;
      transform: translateX(-1px);
    }
  }
`;

export default function Home() {
  const { scrollYProgress } = useViewportScroll();

  const ref = useRef();

  // Stores the start and end scrolling position for our container
  const [scrollPercentageStart, setScrollPercentageStart] = useState(null);
  const [scrollPercentageEnd, setScrollPercentageEnd] = useState(null);

  // const { scrollY, scrollYProgress } = useViewportScroll();
  // Use the container's start/end position percentage

  const yRange = useTransform(
    scrollYProgress,
    [scrollPercentageStart, scrollPercentageEnd],
    [0, 1]
  );
  const pathLength = yRange;

  useLayoutEffect(() => {
    // Get the distance from the start of the page to the element start
    const rect = ref.current.getBoundingClientRect();
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

    const offsetStart = rect.top + scrollTop;
    const offsetEnd = scrollTop + rect.height;

    const elementScrollStart = offsetStart / document.body.clientHeight;
    const elementScrollEnd = offsetEnd / document.body.clientHeight;

    setScrollPercentageStart(elementScrollStart);
    setScrollPercentageEnd(elementScrollEnd);
  });
  return (
    <HomeTopwrap>
      <Travelline />
      <div className="SiteContainer">
        <HomeHeroImage />
      </div>
      <HomeIntrowrap>
        <Mountain1 />
        <Tiger1 />
        <Tree1 />
        <Fort1 />
        <Tree2 />
        <Tree3 />
        <River2 />
        <Tree4 />
        <Mountain2 />
        <Symbol>
          <img src={heroSymbol} alt="" />
        </Symbol>
        <ImagesWrap className="images-wrap">
          <Travelline3 />
          <HomeImage />
        </ImagesWrap>
        <div className="SiteContainer">
          <HomeIntro>
            <div ref={ref} className="homeIntroWrapper ">
              <div className="title">A Closed Gathering</div>
              <div className="text">
                Magnetic Fields Nomads is the first of a series of events that
                will continue to travel and embrace the magic of impermanence
                and discovery.
              </div>
              <div className="animateLIne">
                <div className="star">
                  <img src={starRust} alt="" />
                </div>
                <div className="start" />
                <motion.div>
                  <svg className="progress-icon" viewBox="0 0 2 300">
                    <motion.path
                      fill="none"
                      strokeWidth="4"
                      stroke="#743e3a"
                      strokeDasharray="0 1"
                      d="M0 0 v300 0"
                      style={{
                        pathLength,
                        translateY: 0,
                        scaleX: -1, // Reverse direction of line animation
                      }}
                    />
                  </svg>
                </motion.div>
                <div className="finish">
                  <div className="star">
                    <img src={starRust} alt="" />
                  </div>
                  <p>
                    <span className="i">26.0468° N, 76.4033° E</span>
                    <br />
                    <span className="b-i">Nahargarh, Ranthambhore</span>
                  </p>
                </div>
                <Button linkto="/ranthambore" linktext="Discover Ranthambore" />
              </div>
            </div>
          </HomeIntro>
        </div>
      </HomeIntrowrap>
    </HomeTopwrap>
  );
}

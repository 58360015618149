import React from 'react';
import styled from 'styled-components';
import tree1 from './motifs/tree_3.png';

const Motif1styles = styled.div`
  position: absolute;
  right: -2%;
  top: 20%;
  z-index: 0;
  width: 8vw;
  max-width: 100px;
  opacity: 0.2;
`;

export default function Motif1() {
  return (
    <>
      <Motif1styles>
        <img src={tree1} alt="" />
      </Motif1styles>
    </>
  );
}

import React, { useState, useRef, useEffect, useLayoutEffect } from 'react';
import { motion, useViewportScroll, useTransform } from 'framer-motion';
import styled from 'styled-components';

import homeImage1 from '../../assets/images/home/1.jpg';
import homeImage2 from '../../assets/images/home/2.jpg';
import homeImage5 from '../../assets/images/home/5.jpg';
import homeImage4 from '../../assets/images/home/4.jpg';
import homeImage6 from '../../assets/images/home/6.jpg';
// home experiences

const Image = styled.div`
  position: absolute;
  right: 10%;
  width: 20vw;
  height: 30vw;
  max-width: 350px;
  top: 100px;
  opacity: 1;
  background-size: cover;
  z-index: 100;
`;

const Image2 = styled.div`
  position: absolute;
  left: 15%;
  width: 20vw;
  height: 30vw;
  max-width: 350px;
  top: 800px;
  opacity: 1;
  background-size: 350px;
  background-size: cover;
  z-index: 100;
`;

const Image3 = styled.div`
  position: absolute;
  left: 0%;
  width: 30vw;
  height: 20vw;
  top: 700px;
  opacity: 1;
  background-size: cover;
  z-index: 100;
`;
const Image4 = styled.div`
  position: absolute;
  right: 2%;
  width: 30vw;
  height: 20vw;
  top: 100px;
  opacity: 1;
  background-size: cover;
  z-index: 101;
`;

const Image5 = styled.div`
  position: absolute;
  right: 8%;
  width: 30vw;
  top: 1300px;
  height: 20vw;

  opacity: 1;
  background-size: cover;
  z-index: 100;
`;

export default function HomeImage() {
  const { scrollYProgress } = useViewportScroll();

  const ref = useRef();

  // Stores the start and end scrolling position for our container
  // const [scrollPercentageStart, setScrollPercentageStart] = useState(null);
  // const [scrollPercentageEnd, setScrollPercentageEnd] = useState(null);

  // const { scrollY, scrollYProgress } = useViewportScroll();
  // Use the container's start/end position percentage

  const yRange1 = useTransform(scrollYProgress, [0, 1], [0, -400]);
  const yRange2 = useTransform(scrollYProgress, [0, 1], [0, -400]);
  const yRange3 = useTransform(scrollYProgress, [0, 1], [0, -800]);
  const yRange4 = useTransform(scrollYProgress, [0, 1], [0, -800]);
  const pathLength = yRange1;
  const pathLength2 = yRange2;
  const pathLength3 = yRange3;
  const pathLength4 = yRange4;

  // const { scrollYProgress } = useViewportScroll();
  // const scrollY = scrollYProgress.scrollYProgress;
  useLayoutEffect(() => {
    // Get the distance from the start of the page to the element start
    // const rect = ref.current.getBoundingClientRect();
    // const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    // const offsetStart = rect.top + scrollTop;
    // const offsetEnd = scrollTop + rect.height;
    // const elementScrollStart = offsetStart / document.body.clientHeight;
    // const elementScrollEnd = offsetEnd / document.body.clientHeight;
    // setScrollPercentageStart(elementScrollStart);
    // setScrollPercentageEnd(elementScrollEnd);
  });
  return (
    <>
      <motion.div
        ref={ref}
        style={{ rotate: 0, y: pathLength }}
        transition={{ type: 'spring', stiffness: 100 }}
      >
        <Image style={{ backgroundImage: `url(${homeImage1})` }} />
      </motion.div>
      <motion.div
        ref={ref}
        style={{ rotate: 0, y: pathLength2 }}
        transition={{ type: 'spring', stiffness: 100 }}
      >
        <Image2 style={{ backgroundImage: `url(${homeImage2})` }} />
      </motion.div>
      <motion.div
        ref={ref}
        style={{ rotate: 0, y: pathLength3 }}
        transition={{ type: 'spring', stiffness: 100 }}
      >
        <Image3 style={{ backgroundImage: `url(${homeImage5})` }} />
      </motion.div>
      <motion.div
        ref={ref}
        style={{ rotate: 0, y: pathLength4 }}
        transition={{ type: 'spring', stiffness: 100 }}
      >
        <Image4 style={{ backgroundImage: `url(${homeImage4})` }} />
      </motion.div>
      <motion.div
        ref={ref}
        style={{ rotate: 0, y: pathLength4 }}
        transition={{ type: 'spring', stiffness: 100 }}
      >
        <Image5 style={{ backgroundImage: `url(${homeImage6})` }} />
      </motion.div>
    </>
  );
}
